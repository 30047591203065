import React, { useEffect, useState, useContext } from "react"
// import PropTypes from "prop-types"
import tw, { css } from "twin.macro"
import { Formik } from "formik"
import { contactFormSend } from "../../services/contactForm"
import { GeneralContext } from "../../contexts/generalContext"
import isClient from "../../helpers/isClient";

const FormularioDistribuidor = ({ nombreFormulario }) => {
  const generalContext = useContext(GeneralContext)
  const { t, langCode } = generalContext
  const formularioEnviado = error => {
    if (error) {
      generalContext.updateLoading({
        loadingState: "loaded",
        textoLoading1: "",
        textoLoaded1: t.loadedWrong1,
        textoLoaded2: t.loadedWrong2,
      })
    } else {
      generalContext.updateLoading({
        loadingState: "loaded",
        textoLoading1: "",
        textoLoaded1: t.loadedOk1,
        textoLoaded2: t.loadedOk2,
      })
    }
  }
  return (
    <div css={tw`md:grid md:grid-cols-12 md:gap-1`}>
      <div css={tw`md:col-span-5 md:pr-6`}>
        <div
          css={tw`py-2 md:pb-4 text-gris1 dark:text-gris4 font-light text-obmed2 md:text-obhyperbig uppercase`}
        >
          {t.forms.ask}
        </div>
        <div
          css={[
            tw`text-gris1 dark:text-gris4 text-obmed2 md:text-base font-light`,
            cssParrafo,
          ]}
        >
          <p>{t.dondeComprar.texto1}</p>

          <p>
            {t.dondeComprar.texto2a} <strong>{t.dondeComprar.texto2b}</strong>.
          </p>
        </div>
      </div>
      <div css={tw`md:col-span-7 pb-6 md:pb-12`}>
        <Formik
          initialValues={{
            nombre: "",
            email: "",
            message: "",
            pais: "",
            movil: "",
            cp: "",
            autorizacion: "",
            razon: "conocerpuntoventa",
          }}
          validate={values => {
            const errors = {}
            if (!values.email) {
              errors.email = true
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
              errors.email = true
            }
            if (!values.nombre) {
              errors.nombre = true
            }
            if (!values.movil) {
              errors.movil = true
            }
            if (!values.pais) {
              errors.pais = true
            }
            if (!values.cp) {
              errors.cp = true
            }
            if (!values.autorizacion) {
              errors.autorizacion = true
            }
            if (values.razon === 'otro' && !values.message) {
              errors.message = true
            }


            return errors
          }}
          onSubmit={(values, { setSubmitting }) => {
            generalContext.updateLoading({
              loadingState: "loading",
              textoLoading1: t.forms.sending,
            })
            if(isClient){
                window.gtag( 'event', 'Form Submission', { 'event_category': 'Forminator'} );
            }
            if(values.razon !== 'otro'){
              delete values.message
            }

            contactFormSend(
              values,
              setSubmitting,
              formularioEnviado,
              nombreFormulario ? nombreFormulario : "Formulario General",
                langCode
            )
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            /* and other goodies */
          }) => {
            return (
              <form onSubmit={handleSubmit}>
                <hr css={tw`block md:hidden mt-6 border-t border-marron2`} />

                <TextAreaFields
                  {...{
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                  }}
                />
                <hr css={tw`block mt-6 border-t border-marron2`} />

                <div
                  css={tw`text-obsmall2 text-gris1 dark:text-gris4  font-semibold pb-2 pt-6 md:pt-8 uppercase`}
                >
                  {t.forms.yourInfo}
                </div>
                <div css={tw`md:grid md:grid-cols-7 md:gap-1`}>
                  <div css={tw`md:col-span-7`}>
                    <label css={twLabel}>{t.forms.name}</label>
                    <input
                      css={[
                        twInputText,
                        errors.nombre && touched.nombre && twInputTextError,
                      ]}
                      type="text"
                      name="nombre"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.nombre}
                    />
                  </div>
                  <div css={tw`md:col-span-4`}>
                    <label css={twLabel}>{t.forms.email}</label>
                    <input
                      css={[
                        twInputText,
                        errors.email && touched.email && twInputTextError,
                      ]}
                      type="email"
                      name="email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                    />
                  </div>
                  <div css={tw`md:col-span-3`}>
                    <label css={twLabel}>{t.forms.phone}</label>
                    <input
                      css={twInputText}
                      css={[
                        twInputText,
                        errors.movil && touched.movil && twInputTextError,
                      ]}type="text"
                      name="movil"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.movil}
                    />
                  </div>
                  <div css={tw`md:col-span-5`}>
                    <label css={twLabel}>{t.forms.country}</label>
                    <input
                      css={[
                        twInputText,
                        errors.pais && touched.pais && twInputTextError,
                      ]}
                      type="text"
                      name="pais"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.pais}
                    />
                  </div>
                  <div css={tw`md:col-span-2`}>
                    <label css={twLabel}>{t.forms.cp}</label>
                    <input
                      css={[
                        twInputText,
                        errors.cp && touched.cp && twInputTextError,
                      ]}
                      type="text"
                      name="cp"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.cp}
                    />
                  </div>
                </div>

                <hr css={tw`block mt-6 border-t border-marron2`} />

                <div css={tw`md:flex pt-3 md:pt-5`}>
                  <div css={tw`md:flex-1`}>
                    <label>
                      <input
                        type="checkbox"
                        name="autorizacion"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.autorizacion}
                      />
                      <div
                        css={[
                          tw`pt-1 pb-3 md:pb-0 md:pr-4 text-obsmall2 text-marron1`,
                          errors.autorizacion &&
                            touched.autorizacion &&
                            tw`text-red-500`,
                        ]}
                      >
                        {t.forms.legalText}
                      </div>
                    </label>
                  </div>
                  <div css={tw`text-right`}>
                    <button
                      css={tw`bg-marron3 hover:bg-marron2 active:bg-marron1 w-20 h-10 text-white uppercase text-obsmall2 disabled:opacity-50 focus:outline-none`}
                      type="submit"
                      disabled={isSubmitting}
                    >
                      {t.forms.send}
                    </button>
                  </div>
                </div>
              </form>
            )
          }}
        </Formik>
      </div>
    </div>
  )
}

const TextAreaFields = props => {
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    /* and other goodies */
  } = props
  const generalContext = useContext(GeneralContext)
  const { t } = generalContext
  const [isTextAreaVisible, setTextAreaVisible] = useState(false)
  useEffect(() => {
    setTextAreaVisible(values.razon === "otro")
    //onsole.log("touched")
    //onsole.log(touched)
  }, [values])
  return (
    <>
      <div
        css={tw`text-obsmall2 text-gris1 dark:text-gris4  font-semibold pt-7 pb-2 uppercase`}
      >
        {t.forms.select.question}
      </div>
      <div>
        <div onChange={handleChange}>
          <label css={cssLabelRadio}>
            <input
              type="radio"
              value="conocerpuntoventa"
              name="razon"
              defaultChecked
            />{" "}
            {t.forms.select.answer1}
          </label>
          <label css={cssLabelRadio}>
            <input type="radio" value="contactocomercial" name="razon" />{" "}
            {t.forms.select.answer2}
          </label>
          <label css={cssLabelRadio}>
            <input type="radio" value="otro" name="razon" />{" "}
            {t.forms.select.answer3}
          </label>
        </div>
      </div>
      <div css={isTextAreaVisible ? tw`block` : tw`hidden`}>
        <label css={twLabel}>{t.forms.select.message}</label>
        <textarea
          css={[
            twInputText,
            errors.message && touched.message && twInputTextError,
          ]}
          name="message"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.message}
        />
      </div>
    </>
  )
}

const cssParrafo = css`
  p {
    ${tw`mb-4`}
  }
  strong {
    ${tw`font-bold`}
  }
`

export default FormularioDistribuidor
const twInputTextContent = tw`w-full`
const twInputText = tw`w-full md:flex-1  border-white dark:border-gris3 border-2 bg-white dark:bg-gris3 placeholder-marron1 placeholder-opacity-70 text-gris1 text-base md:text-obsmall2 focus:border-marron1 outline-none focus:outline-none`
const twInputTextError = tw`border-red-800 dark:border-red-800 bg-red-200 dark:bg-red-200 text-red-800 placeholder-red-800`
const twLabel = tw`block text-obsmall2 text-gris1 dark:text-gris4 pt-3 pb-1`
const cssLabelRadio = css`
  & {
    ${tw`block`}
    ${tw`text-obsmall2`}
        ${tw`font-bold`}
        ${tw`text-gris1 dark:text-gris4`}
        ${tw`pt-3`}
        ${tw`pb-1`}
        ${tw`pl-6`}
  }
  input {
    ${tw`-ml-6`}
    ${tw`mr-1`}
  }
`
